import { TSchema, Type } from "@sinclair/typebox";
import {
  ServiceProviderEntityType,
  ServiceProviderLendingPartnerType,
  ServiceProviderStatusType,
  ServiceProviderType
} from "../../entities";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { BaseAddressSchema } from "../location.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import {
  ArrayField,
  ArrayStringOrUndefined,
  BooleanOrUndefined,
  DateField,
  EnumField,
  EnumOrUndefined,
  LiteralField,
  NumberField,
  NumberOrUndefined,
  StringField,
  StringOrUndefined
} from "../utils.typebox";

const BaseServiceProvider = <T extends TSchema>(type: T) =>
  Type.Object({
    type: type,
    partyIds: Type.Array(StringField)
  });

const CompanyServiceProviderFields = Type.Object({
  name: StringField,
  status: EnumField(ServiceProviderStatusType),
  ein: StringOrUndefined,
  notes: StringOrUndefined,
  experienceLevel: NumberOrUndefined
});

const BranchServiceProviderFields = Type.Object({
  name: StringField,
  status: EnumField(ServiceProviderStatusType),
  ein: StringOrUndefined,
  Address: Type.Partial(BaseAddressSchema),
  phoneNumber: StringOrUndefined,
  email: StringOrUndefined,
  coverageArea: StringOrUndefined,
  numberOfEmployees: NumberOrUndefined,
  nmlsLicenseNumber: StringOrUndefined,
  lendingPartnerType: EnumOrUndefined(ServiceProviderLendingPartnerType),
  wireRoutingNumber: StringOrUndefined,
  wireAccountNumber: StringOrUndefined,
  wireNameOnAccount: StringOrUndefined,
  notes: StringOrUndefined,
  experienceLevel: NumberOrUndefined
});

const RepServiceProviderFields = Type.Object({
  status: EnumField(ServiceProviderStatusType),
  firstName: StringField,
  middleName: StringOrUndefined,
  lastName: StringField,
  Address: Type.Partial(BaseAddressSchema),
  phoneNumber: StringOrUndefined,
  email: StringOrUndefined,
  licenseNumber: StringOrUndefined,
  driversLicenseNumber: StringOrUndefined,
  birthDate: DateField,
  businessRegistrationStates: ArrayStringOrUndefined,
  nmlsLicenseNumber: StringOrUndefined,
  notes: StringOrUndefined,
  experienceLevel: StringOrUndefined
});

export const CompanyServiceProviderSchema = Type.Intersect([
  BaseEntitySchema,
  BaseServiceProvider(LiteralField(ServiceProviderType.Company)),
  Type.Object({
    domainConfigurationId: StringField
  }),
  CompanyServiceProviderFields
]);

export const BranchServiceProviderSchema = Type.Intersect([
  BaseEntitySchema,
  BaseServiceProvider(LiteralField(ServiceProviderType.Branch)),
  Type.Object({
    companyId: StringField
  }),
  BranchServiceProviderFields
]);

export const RepServiceProviderSchema = Type.Intersect([
  BaseEntitySchema,
  BaseServiceProvider(LiteralField(ServiceProviderType.Representative)),
  Type.Object({
    companyId: StringField,
    skipped: BooleanOrUndefined
  }),
  RepServiceProviderFields
]);
export const CompanyServiceProviderCreate = OmitCreateSchema(
  CompanyServiceProviderSchema
);
export const BranchServiceProviderCreate = OmitCreateSchema(
  BranchServiceProviderSchema
);
export const RepServiceProviderCreate = OmitCreateSchema(
  RepServiceProviderSchema
);

export const CompanyServiceProviderUpdate = OmitUpdateSchema(
  CompanyServiceProviderSchema
);
export const BranchServiceProviderUpdate = OmitUpdateSchema(
  BranchServiceProviderSchema
);
export const RepServiceProviderUpdate = OmitUpdateSchema(
  RepServiceProviderSchema
);

export const RolodexServiceProviderCreate = Type.Union([
  CompanyServiceProviderCreate,
  BranchServiceProviderCreate,
  RepServiceProviderCreate
]);
export const RolodexServiceProviderUpdate = Type.Union([
  CompanyServiceProviderUpdate,
  BranchServiceProviderUpdate,
  RepServiceProviderUpdate
]);

export const RolodexServiceProviderSchemaRequest = Type.Object({
  company: Type.Partial(
    Type.Intersect([
      Type.Object({
        id: StringField,
        domainConfigurationId: StringField
      }),
      BaseServiceProvider(LiteralField(ServiceProviderType.Company)),
      CompanyServiceProviderFields
    ])
  ),
  branch: Type.Partial(
    Type.Intersect([
      Type.Object({
        id: StringField,
        companyId: StringField
      }),
      BaseServiceProvider(LiteralField(ServiceProviderType.Branch)),
      BranchServiceProviderFields
    ])
  ),
  rep: Type.Partial(
    Type.Intersect([
      Type.Object({
        id: StringField,
        companyId: StringField,
        skipped: BooleanOrUndefined
      }),
      BaseServiceProvider(LiteralField(ServiceProviderType.Representative)),
      RepServiceProviderFields
    ])
  ),
  dealId: NumberField,
  entityType: EnumField(ServiceProviderEntityType),
  entityIds: ArrayField(StringField)
});
