import { Box } from "@chakra-ui/react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { LoginPageProps, userDispatcher } from "../../../shared";
import Login from "./Login";

const mapStateToProps = (state) => ({
  userData: state.userData
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...userDispatcher.dispatchers(dispatch)
});

const LoginPage: React.FC<LoginPageProps> = (props) => {
  return (
    <Box bg="white" w="100%">
      <Login {...props} />
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
