import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { DocumentMetadata } from "@elphi/types";
import { WorkInProgress } from "../animations/WorkInProgress";

// import FileViewer from "react-file-viewer";
// export const ElphiFileViewer = (props: {
//   file: DocumentMetadata;
//   url: string;
// }) => {
//     const docFormat = props.file.data.fileName.split(".").at(-1);
//     return (
//       <FileViewer
//         fileType={docFormat}
//         filePath={props.url}
//         onError={() => {
//           console.log("failed");
//         }}
//       />
//     );
//   return (
//     <iframe
//       src={`https://docs.google.com/viewer?url=${props.url}`}
//       style={{
//         width: "600px",
//         height: "500px"
//       }}
//     ></iframe>
//   );
// };

export const ElphiFileViewerModal = (props: {
  file: DocumentMetadata;
  url: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Box p="10px">
            <Text whiteSpace={"break-spaces"}>{props.file.data.fileName}</Text>
          </Box>
        </ModalHeader>

        <ModalBody>
          {/* <ElphiFileViewer file={props.file} url={props.url} /> */}
          <Box>
            <WorkInProgress />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
