import { Task } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState, store } from "../redux/store";
import { taskApi } from "../redux/v2/task/task.service";
import { taskSlice } from "../redux/v2/task/task.slice";
import { getListFromDictionary } from "../utils/batchUtils";

export const useTaskHooks = () => {
  const [getTask, getTaskResponse] = taskApi.useLazyGetQuery();
  const [updateBatchApi] = taskApi.useBatchUpdateMutation();
  const [updateTaskApi, updateTaskResponseApi] = taskApi.useUpdateMutation();
  const [createTaskApi, createTaskApiResponse] = taskApi.useCreateMutation();
  const [createTaskFolderApi, createTaskFolderApiResponse] =
    taskApi.useCreateTaskFolderMutation();
  const [uploadTaskFileApi, uploadTaskFileApiResponse] =
    taskApi.useUploadTaskFileMutation();
  const [uploadMultipleTaskFilesApi, uploadMultipleTaskFilesApiResponse] =
    taskApi.useUploadMultipleTaskFilesMutation();

  const [deleteTaskFileApi, deleteTaskFileApiResponse] =
    taskApi.useDeleteTaskFileMutation();
  const [getDownloadUrlApi, getDownloadUrlApiResponse] =
    taskApi.useGetDownloadUrlMutation();

  const [generateManualTasksApi, generateManualTasksApiResponse] =
    taskApi.useGenerateManualTasksMutation();
  const [generateTasksApi, generateTasksApiResponse] =
    taskApi.useGenerateTasksMutation();

  const setSelectedTask = (id: string) =>
    store.dispatch(taskSlice.actions.selectedId({ id }));

  const setFilteredTask = (ids: string[]) =>
    store.dispatch(taskSlice.actions.filteredIds({ ids }));

  const selectedTask = useSelector(
    (state: RootState) =>
      state.task.selectedId && state.task.entities[state.task.selectedId]
  );

  const taskState = useSelector((state: RootState) => state.task);

  const createTask = async (newTask: Task) => {
    const response = await createTaskApi(newTask);
    return responseHandler(response);
  };
  const updateTask = async (newTask: { id: string } & Partial<Task>) => {
    const response = await updateTaskApi(newTask);
    return responseHandler(response);
  };

  const updateBatch = async (r: {
    tasks: ({ id: string } & Partial<Task>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const generateTasks = async (r: {
    dealId?: string;
    partyId?: string;
    serviceProviderId?: string;
  }) => {
    return await generateTasksApi(r).then(responseHandler);
  };
  const generateManualTasks = async (r: {
    dealId: string[];
    partyId: string[];
    propertyId: string[];
    assetId: string[];
    statementId: string[];
    taskConfigurationId: string[];
    serviceProvderId: string[];
  }) => {
    return await generateManualTasksApi(r).then(responseHandler);
  };
  const updateTasksHandler = async (
    data:
      | {
          [id: string]: {
            id: string;
          } & Partial<Task>;
        }
      | undefined
  ) => {
    if (!data) return;
    const tasks = getListFromDictionary(data);
    if (!tasks.length) return;
    return await updateBatch({ tasks } as {
      tasks: ({
        id: string;
      } & Partial<Task>)[];
    });
  };
  return {
    updateTasksHandler,
    getTask,
    getTaskResponse,
    createTask,
    createTaskApiResponse,
    updateTask,
    updateTaskResponseApi,
    updateBatch,
    setSelectedTask,
    setFilteredTask,
    selectedTask,
    taskState,
    generateTasks,
    generateTasksApiResponse,
    uploadTaskFileApiResponse,
    uploadTaskFileApi,
    uploadMultipleTaskFilesApi,
    uploadMultipleTaskFilesApiResponse,
    deleteTaskFileApi,
    deleteTaskFileApiResponse,
    getDownloadUrlApi,
    getDownloadUrlApiResponse,
    createTaskFolderApi,
    createTaskFolderApiResponse,
    generateManualTasks,
    generateManualTasksApi,
    generateManualTasksApiResponse
  };
};

export default useTaskHooks;
