// react-script v5 does not support fs...
// https://github.com/facebook/create-react-app/issues/11756
// import fs from "fs";

// export const download = function (url: string, dest: string, cb: any) {
//   const file = fs.createWriteStream(dest);
//   fetch(url).then((response: any) => {
//     response.pipe(file);
//     file.on("finish", () => {
//       file.close(cb);
//     });
//   });
// };

export const download = (url: string, dest: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", dest);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
};
