import { Box, keyframes } from "@chakra-ui/react";
import { motion } from "framer-motion";

const animationKeyframes = keyframes`
  0% { transform: scale(2)   translateX(0%) ;opacity: 1   }
  25% { transform: scale(2.3)  translateX(20%);opacity: 1   }
  50% { transform: scale(2.5)  translateX(40%);opacity: 1   }
  75% { transform: scale(2.3)  translateX(60%);opacity: 1   }
  100% { transform: scale(2) translateX(80%);opacity: 1  }
`;
const animation = `${animationKeyframes} 15s reverse ease-in-out infinite`;

const ElphiAnimation = (props: {
  component?: JSX.Element;
  animation?: string;
}) => {
  return (
    <Box
      w="100%"
      as={motion.div}
      animation={props.animation || animation}
      // not work: transition={{ ... }}
      padding="2"
      // @ts-ignore - "Does not exist" Type Error against Motion
      // bgGradient="linear(to-l, #7928CA, #FF0080)"

      display="flex"
    >
      {props.component}
    </Box>
  );
};

export default ElphiAnimation;
