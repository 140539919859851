import { CloneConfigurationType, FieldType } from "@elphi/types";
import { useEffect, useState } from "react";
import { ChecklistInputBuilder } from "../../../../task/export-checklist/ChecklistInputBuilder";
import { createOptionsFromEnum } from "../../../../utils/formUtils";

export type CloneConfigurationCheckboxProps = {
  isDisabled: boolean;
  onChangeSelectConfiguration: (v: CloneConfigurationType[]) => void;
  selectConfiguration: CloneConfigurationType[];
};

export const CloneConfigurationCheckbox = (
  props: CloneConfigurationCheckboxProps
) => {
  const [isRoleOptionDisabled, setIsRoleOptionDisabled] =
    useState<boolean>(false);

  const shouldSetRoleAsChecked = (v: CloneConfigurationType) =>
    v === CloneConfigurationType.userManagement ||
    v === CloneConfigurationType.taskManagement;

  const onSelectConfiguration = (v?: CloneConfigurationType[]) => {
    if (v?.some(shouldSetRoleAsChecked)) {
      if (!v.includes(CloneConfigurationType.role)) {
        v.push(CloneConfigurationType.role);
      }
      props.onChangeSelectConfiguration(v);
      setIsRoleOptionDisabled(true);
      return;
    }
    props.onChangeSelectConfiguration(v || []);
    setIsRoleOptionDisabled(false);
  };

  useEffect(() => {
    onSelectConfiguration(props.selectConfiguration);
  }, []);

  const selectConfigurationOptions = createOptionsFromEnum(
    CloneConfigurationType
  )
    .map((x) => {
      const isDisabled =
        x.value === CloneConfigurationType.role ? isRoleOptionDisabled : false;
      return {
        ...x,
        isDisabled,
        tooltipText: getTooltipText(x.value, isDisabled)
      };
    })
    .filter((x) => x.label && x.value);

  return (
    <ChecklistInputBuilder
      isDisabled={props.isDisabled}
      title={"Select Configuration"}
      fieldType={FieldType.MultiCheckbox}
      onSetState={onSelectConfiguration}
      options={selectConfigurationOptions}
      selectData={props.selectConfiguration}
      headerStyle={{
        paddingLeft: "5px"
      }}
    />
  );
};

const getTooltipText = (value: string, isDisabled?: boolean) => {
  if (value === CloneConfigurationType.role && isDisabled) {
    return "Roles is required for your selected Configurations";
  }
  return undefined;
};
