import { DocumentMetadata } from "@elphi/types";
import pizzip from "pizzip";
import { responseHandler } from "../../apis/rtk/response.handler";
import useTaskHooks from "../../hooks/task.hooks";
import { useElphiToast } from "../toast/toast.hook";
import { TaskWithMetaData } from "./TaskTable";

const useDownloadTaskHooks = (props: {
  tasks: TaskWithMetaData[];
  selectedFiles?: { [a: string]: boolean };
}) => {
  const FIRST_DOCUMENT = 0;

  const { getDownloadUrlApi } = useTaskHooks();
  const { errorToast } = useElphiToast();

  const selectedDocs = props.selectedFiles
    ? Object.keys(props.tasks?.[FIRST_DOCUMENT]?.documents || {})
        .filter((d) => props?.selectedFiles?.[d] || false)
        .map((d) => props.tasks?.[FIRST_DOCUMENT]?.documents?.[d]!)
    : null;

  const generateZip = (zip: pizzip, fileName: string) => {
    const mainZipBuffer = zip.generate({
      type: "blob",
      mimeType: "application/zip",
      compression: "DEFLATE"
    });
    const url = window.URL.createObjectURL(mainZipBuffer);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadAndZipSingleFile = (props: {
    mainZip: pizzip;
    file: DocumentMetadata;
    folderName?: string;
  }) => {
    return new Promise((resolve, reject) => {
      const { mainZip, file, folderName } = props;
      getDownloadUrlApi({
        document: file
      })
        .then(responseHandler)
        .then((r) => {
          if (r.status === 200) {
            fetch(r.data.url)
              .then((response) => response.blob())
              .then(async (blob) => {
                const arrayBuffer = await blob.arrayBuffer();
                if (folderName)
                  resolve(
                    mainZip
                      .folder(folderName)
                      .file(file.data.fileName, arrayBuffer)
                  );
                else resolve(mainZip.file(file.data.fileName, arrayBuffer));
              });
          }
          if (r.status === 400) {
            errorToast({
              title: "failed to download",
              description: r.data?.description
            });
            reject();
          }
        });
    });
  };

  const dateToFileString = (date: Date) =>
    date.toLocaleString("en-US").replaceAll("/", ".").replaceAll(":", ".");

  const downloadAndZipTaskFiles = async () => {
    if (selectedDocs) {
      const mainZip = new pizzip();
      await Promise.all(
        selectedDocs.map(async (file) =>
          downloadAndZipSingleFile({ mainZip, file })
        )
      );
      generateZip(
        mainZip,
        `${props.tasks?.[FIRST_DOCUMENT]?.name || "No Task Name"}- ${
          props.tasks?.[FIRST_DOCUMENT].identityInformation || ""
        }_${dateToFileString(new Date())}`
      );
    }
  };

  const downloadAndZipMultipleTaskFiles = async () => {
    if (props.tasks) {
      const mainZip = new pizzip();
      await Promise.all(
        props.tasks
          .filter((t) => t.documents)
          .map(async (task) => {
            return Promise.all(
              Object.values(task.documents!).map((file) =>
                downloadAndZipSingleFile({
                  mainZip,
                  file,
                  folderName: task.identityInformation
                    ? `${task.name} - ${task.identityInformation}`
                    : task.name
                })
              )
            );
          })
      );
      generateZip(mainZip, `${dateToFileString(new Date())}`);
    }
  };

  return {
    downloadAndZipTaskFiles,
    downloadAndZipMultipleTaskFiles
  };
};

export default useDownloadTaskHooks;
