import { Center, Flex, keyframes, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import ElphiAnimation from "./ElphiAnimation";
const animationKeyframes = keyframes`
0% { transform: scale(2)   translateX(0%) ;opacity: 1   }
25% { transform: scale(2.3)  translateX(20%);opacity: 1   }
50% { transform: scale(2.5)  translateX(40%);opacity: 1   }
75% { transform: scale(2.3)  translateX(60%);opacity: 1   }
100% { transform: scale(2) translateX(80%);opacity: 1  }
`;
const animation1 = `${animationKeyframes} 15s reverse ease-in-out infinite`;

const animation2 = `${animationKeyframes} 20s reverse ease-in-out infinite`;
export const WorkInProgress = () => {
  const [randNum, setRandNum] = useState([0, 0, 0]);
  useEffect(() => {
    const interval = setInterval(() => {
      setRandNum([Math.random(), Math.random(), Math.random()]);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Center pt="10px">
        <Text fontSize={"2xl"}> 👷 👷 Under Construction 🚧</Text>
      </Center>
      <ElphiAnimation
        animation={animation1}
        component={
          <Flex w="100%">
            <Flex w="100%">
              🚜 <Text opacity={randNum[0] % 5}>💨</Text> 🚜{" "}
              <Text opacity={randNum[1] % 5}>💨</Text> 🚜{" "}
              <Text opacity={randNum[2] % 5}>💨</Text>
            </Flex>
          </Flex>
        }
      />
      <ElphiAnimation
        animation={animation2}
        component={
          <Flex w="100%">
            <Flex w="100%">
              🚜 🚜 🚜 <Text opacity={randNum[0] % 5}>💨</Text>{" "}
              <Text opacity={randNum[2] % 5}>💨</Text>
            </Flex>
          </Flex>
        }
      />
    </>
  );
};
